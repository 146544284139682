(function (root) {

    'use strict';

    /*jslint nomen: true, browser: true, white: true, plusplus: true */
    /*global jQuery */

    if (!root.Caboodal) {
        root.Caboodal = {};
    }

    (function (Caboodal, $) {

        var config = {
            showTooltips: true
        };

        /* Configures the element to display a validation tooltip based on its data attributes */
        function setupTooltip(element) {
            if (!config.showTooltips) {
                return;
            }

            var $element = $(element);
            $element.qtip({
                show: {
                    event: 'focus',
                    ready: $element.is(':focus')
                },
                hide: 'blur',
                content: {
                    text: $element.data('validation-tooltip')
                },
                position: {
                    my: 'left center',
                    at: 'right center',
                    adjust: {
                        x: -5
                    }
                },
                style: {
                    classes: 'validation-tooltip-error'
                }
            });
        }

        /* Identifies the ID of the control which a validator validates */
        function getValidatedControl(validator) {
            return validator.controltovalidate || $(validator).data('validatedcontrol');
        }

        /* Validation rules and configuration */
        Caboodal.validation = {
            /* A collection of rules which can be used to validate clientside input */
            rules: {
                /* Validates that a field contains a value */
                required: function (controlToValidate) {
                    var value = $('#' + controlToValidate).val();
                    return !!value;
                },
                /* Validates that, if a field contains a value, this value is within the maximum length */
                maxLength: function (controlToValidate, options) {
                    var value = $('#' + controlToValidate).val();
                    return !value || (value.length && value.length <= options.maxLength);
                }
            },
            /* Disables validation tooltips for the entire page */
            suppressTooltips: function () {
                config.showTooltips = false;
            }
        };

        // Startup
        Caboodal.ready(function () {

            // Create validation tooltips
            $('[data-validation-tooltip]').each(function () {
                setupTooltip(this);
            });

            // Hook up ASP.Net validators to update with the error css class and tooltip
            (function () {
                var updateDisplay = window.ValidatorUpdateDisplay;
                window.ValidatorUpdateDisplay = function (val) {
                    var errors = false
                      , tooltip = []
                      , validatedControl = getValidatedControl(val)
                      , $element = $('#' + validatedControl)
                    ;

                    $.each(window.Page_Validators, function (i, item) {
                        if (getValidatedControl(item) === validatedControl && !item.isvalid) {
                            errors = true;
                            if (item.errormessage && $(item).data('showtooltip')) {
                                tooltip.push(item.errormessage);
                            }
                        }
                    });

                    if (errors) {
                        $element.addClass('error');
                    }
                    else {
                        $element.removeClass('error');
                    }

                    if (tooltip.length > 0) {
                        $element.data('validation-tooltip', tooltip.join('<br />'));
                        setupTooltip($element);
                    } else {
                        $element.qtip('destroy');
                    }

                    updateDisplay(val);
                };
            }());

        });

    }(root.Caboodal, jQuery));

}(window));
;